/* hs-eslint ignored failing-rules */

import env from 'enviro';
import { Flow } from 'signup-constants/Flow';
import { SignupQueryKeys } from 'signup-constants/SignupQueryKeys';
import { SignupUrlGenerator } from 'signup-ui-url-generator/SignupUrlGenerator';
import { stringify } from 'hub-http/helpers/params';
const LOCALHOST_AUTH_URL = /^http:\/\/(?:localhost|127.0.0.1):(?:[0-9]{1,5})$/;
const VALID_URL_REGEX = new RegExp(/(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-/]))?/);
const getDefaultSignupArgs = (isFbSignin, pageId) => {
  return isFbSignin ? [Flow.FbCrm, {
    query: {
      [SignupQueryKeys.Intent]: 'fbCrm',
      page_id: pageId
    }
  }] : [Flow.Crm];
};
export const getIsChatSpot = () => {
  const locationPathname = window.location.pathname;
  const locationSearch = window.location.search;
  return locationPathname.indexOf('chatspot') !== -1 || locationSearch.indexOf('chatspotBranding') !== -1;
};
export const getIsConnect = () => {
  const locationPathname = window.location.pathname;
  const locationSearch = window.location.search;
  return locationPathname.indexOf('connect') !== -1 || locationSearch.indexOf('connectBranding') !== -1;
};
export const returnLoginRedirectOrChatspotRedirect = (redirectUrl, portalId) => {
  const isChatSpot = getIsChatSpot();
  const isConnect = getIsConnect();
  if (isChatSpot) {
    if (redirectUrl && redirectUrl.includes('chatspot')) {
      return redirectUrl;
    } else {
      const redirectPortalId = portalId ? portalId.toString() : '';
      const qaVar = env.isQa() ? 'qa' : '';
      return `https://app.hubspot${qaVar}.com/chatspot/${redirectPortalId}`;
    }
  } else if (isConnect) {
    if (redirectUrl && redirectUrl.includes('connect')) {
      return redirectUrl;
    } else {
      const qaVar = env.isQa() ? 'qa' : '';
      return `https://app.hubspot${qaVar}.com/connect-beta`;
    }
  } else {
    return redirectUrl;
  }
};
export const getSignupArgs = (signupParams, isFbSignin, pageId) => {
  let args;
  try {
    args = JSON.parse(signupParams);
  } catch (e) {
    if (!(e instanceof SyntaxError)) {
      throw e;
    }
  }
  return Array.isArray(args) ? args : getDefaultSignupArgs(isFbSignin, pageId);
};
export const getSignupUrl = (signupParams, isFbSignin, pageId, loginRedirectUrlQueryParam) => {
  const args = getSignupArgs(signupParams, isFbSignin, pageId);
  const options = args[1] === undefined ? {
    query: {}
  } : args[1];
  options['env'] = env.isQa() ? 'qa' : 'prod';
  options.query['hubs_signup-cta'] = 'login-signup-cta';
  options.query['hubs_signup-url'] = env.isQa() ? /* eslint-disable-next-line hubspot-dev/prefer-hubspot-url-utils */
  'app.hubspotqa.com/login' : /* eslint-disable-next-line hubspot-dev/prefer-hubspot-url-utils */
  'app.hubspot.com/login';
  if (loginRedirectUrlQueryParam) {
    options.query = Object.assign({
      loginRedirectUrl: loginRedirectUrlQueryParam
    }, options.query);
  }
  const isChatSpot = getIsChatSpot();
  const isConnect = getIsConnect();
  if (isChatSpot) {
    return new SignupUrlGenerator(Flow.ChatSpot, options).getUrl();
  } else if (isConnect) {
    const qaVar = env.isQa() ? 'qa' : '';
    const params = {};
    if (loginRedirectUrlQueryParam) {
      params.loginRedirectUrl = loginRedirectUrlQueryParam;
    }
    const queryString = stringify(params);
    return `https://app.hubspot${qaVar}.com/signup-hubspot/connect?${queryString}`;
  } else {
    return new SignupUrlGenerator(args[0], options).getUrl();
  }
};
export const getRootUrl = () => '/login';
export const isLocalhostAuthUrl = url => LOCALHOST_AUTH_URL.test(url);
export const getSubDomain = () => {
  let subdomain = env.deployed() ? 'app' : 'local';
  const hublet = env.getHublet();
  if (hublet !== 'na1') {
    subdomain = `${subdomain}-${hublet}`;
  }
  return subdomain;
};
export const getWindowUrl = (extra = '', overWrittenSubdomain) => {
  let subdomain;
  if (!overWrittenSubdomain) {
    subdomain = getSubDomain();
  } else {
    subdomain = overWrittenSubdomain;
  }
  const qaVar = env.isQa() ? 'qa' : '';
  return `https://${subdomain}.hubspot${qaVar}.com${extra}`;
};
export const isUrl = url => typeof url !== 'string' ? false : VALID_URL_REGEX.test(url);
export const getDomain = () => {
  const qaVar = env.isQa() ? 'qa' : '';
  return `.hubspot${qaVar}.com`;
};
export const getIsQa = () => env.isQa();
export const getQueryLang = () => {
  const {
    search
  } = window.location;
  if (search) {
    const query = new URLSearchParams(search);
    return query.get('lang');
  }
};