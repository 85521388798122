import enviro from 'enviro';
export const TIMEOUT_MIN_MS = 0;
export const TIMEOUT_MAX_MS = 60000;
export const TIMEOUT_DEFAULT = 8000;
export const DEFAULT_MAX_AGE = 0;
export const DEFAULT_IDENTIFIER_TYPE = 'PORTAL_ID';
export const DEFAULT_OPERATION_MODE = 'default';
export const DEFAULT_API_PREFIX = enviro.getHublet() === 'eu1' ? 'app-eu1' : 'app';
export const DEFAULT_API_DOMAIN = enviro.isProd() ? `https://${DEFAULT_API_PREFIX}.hubspot.com/api` : `https://${DEFAULT_API_PREFIX}.hubspotqa.com/api`;
export const ENDPOINT_VERSION = 'v3';
export const ENDPOINT_EXPOSURE_BULK_LOG = `treatments/${ENDPOINT_VERSION}/exposure/log`;
export const ENDPOINT_TREATMENTS_BULK_GET = `treatments/${ENDPOINT_VERSION}/get`;
export const STORE_CONFIGURATION = {
  namespace: 'laboratory',
  global: false
};

// This comes from https://git.hubteam.com/HubSpot/Laboratory/blob/master/LaboratoryBase/src/main/java/com/hubspot/laboratory/models/experiment/IdentifierType.java
export const IDENTIFIER_TYPES = Object.freeze({
  PORTAL_ID: 'PORTAL_ID',
  USER_ID: 'USER_ID',
  PORTAL_USER: 'PORTAL_USER',
  UTK: 'UTK',
  OTHER: 'OTHER'
});
export const IDENTIFIER_TYPE_MAP = Object.freeze({
  [IDENTIFIER_TYPES.PORTAL_ID]: 'portalId',
  [IDENTIFIER_TYPES.USER_ID]: 'userId',
  [IDENTIFIER_TYPES.PORTAL_USER]: 'portalUser',
  [IDENTIFIER_TYPES.UTK]: 'utk',
  [IDENTIFIER_TYPES.OTHER]: 'other'
});
export const DEFAULT_STATUS = Object.freeze({
  isActive: false,
  isEnrolled: false,
  isDefaulted: true,
  isCached: false,
  isOverridden: false
});
export const DEFAULT_CACHE_STATUS = Object.freeze({
  isActive: true,
  isEnrolled: true,
  isDefaulted: false,
  isCached: true,
  isOverridden: false
});