module.exports = {
  "loginVerificationWithHSAuthenticator": {
    "name": "Login Verification with HubSpot Authenticator",
    "class": "usage",
    "properties": {
      "action": "string",
      "type": [
        "login-verification-hs-authenticator-initiated",
        "login-verification-hs-authenticator-succeded",
        "login-verification-hs-authenticator-failed"
      ]
    },
    "namespace": "LoginUi"
  },
  "loginVerificationWithHSAuthResent": {
    "name": "Login Verification with HubSpot Authenticator resent after error",
    "class": "usage",
    "properties": {
      "action": "string",
      "type": [
        "login-verification-hs-authenticator-resent"
      ]
    },
    "namespace": "LoginUi"
  },
  "loginVerificationWithHSAuthCode": {
    "name": "Login Verification with HubSpot Authenticator Code",
    "class": "usage",
    "properties": {
      "action": "string",
      "type": [
        "login-verification-hs-authenticator-code-initiated",
        "login-verification-hs-authenticator-code-succeded"
      ]
    },
    "namespace": "LoginUi"
  },
  "remeberDevice": {
    "name": "Remember this device",
    "class": "usage",
    "properties": {
      "action": "string",
      "type": [
        "login-and-remeber-device"
      ]
    },
    "namespace": "LoginUi"
  },
  "doNotRemeberDevice": {
    "name": "Do not remember this device",
    "class": "usage",
    "properties": {
      "action": "string",
      "type": [
        "login-and-do-not-remeber-device"
      ]
    },
    "namespace": "LoginUi"
  },
  "loginWith2FAHSAuth": {
    "name": "Login with twoFactor",
    "class": "usage",
    "properties": {
      "action": "string",
      "type": [
        "login-with-2fa-HSAuth-succeded",
        "login-with-2fa-HSAuth-failed"
      ]
    },
    "namespace": "LoginUi"
  },
  "smartLoginPageOpened": {
    "name": "Smart Login page opened",
    "class": "usage",
    "namespace": "LoginUi"
  },
  "smartLoginOptOut": {
    "name": "Smart Login Opt-Out",
    "class": "usage",
    "namespace": "LoginUi"
  },
  "twoFactorRecoveryMobileAuth": {
    "name": "Recovery for Mobile Auth",
    "class": "interaction",
    "properties": {
      "action": "string",
      "type": [
        "hs-authenticator-recovery-lost",
        "hs-authenticator-recovery-new",
        "hs-authenticator-recovery-uninstalled"
      ]
    },
    "namespace": "LoginUi"
  },
  "loginPageView": {
    "name": "Page View for Login",
    "class": "view",
    "namespace": "LoginUi"
  },
  "signupLinkClick": {
    "name": "Signup Link Click",
    "class": "interaction",
    "namespace": "LoginUi"
  }
};